<template>
  <div class="mx-5">
    <div class="keen-slider zoom-out" ref="container">
    <div
      class="keen-slider__slide zoom-out__slide"
      v-for="(src, idx) in sliders"
      :key="idx"
    >
      <div :style="'transform: scale(' + scales[idx] + ')'">
        <img :src="src?.photo" rounded-xl />
      </div>
    </div>
  </div>
</div>
</template>

<script setup lang="ts">
import { ref } from "vue";

defineProps<{
  sliders: []
}>()

const scales = ref([]);
const showSlider = ref(false);
const [container] = useKeenSlider({
  loop: true,
  created(slider: any) {
    let timeout: any;
    let mouseOver = false;

    function clearNextTimeout() {
      clearTimeout(timeout);
    }

    function nextTimeout() {
      clearTimeout(timeout);
      if (mouseOver) return;
      timeout = setTimeout(() => {
        slider.next();
      }, 4000);
    }

    slider.container.addEventListener('mouseover', () => {
      mouseOver = true;
      clearNextTimeout();
    });

    slider.container.addEventListener('mouseout', () => {
      mouseOver = false;
      nextTimeout();
    });

    nextTimeout();

    slider.on('dragStarted', clearNextTimeout);
    slider.on('animationEnded', nextTimeout);
    slider.on('updated', nextTimeout);
  },
  detailsChanged(s: any) {
    const slides = s.track.details.slides;
    scales.value = slides.map((slide: any) => {
      const scaleSize = 0.7;
      const scale = 1 - (scaleSize - scaleSize * slide.portion);
      return scale;
    });
  },
  initial: 2,
});

</script>

<style>
.zoom-out {
  perspective: 1000px;
  height: 50vw;
}

@media (min-width: 768px) {
  .zoom-out {
    height: 800px;
  }
}

.zoom-out__slide div {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}

.zoom-out__slide img {
  background-color: transparent;
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
}
</style>